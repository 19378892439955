import { ref, Ref } from "vue";
import fileDownload from "js-file-download";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
import { CustomersFilter, TerritoryFilter } from "@/models";
import {
  IGetCustomerForViewDto,
  IGetCustomerTerritoryForViewDto,
  CustomerTerritoriesServiceProxy,
  CustomersServiceProxy,
  CreateOrEditCustomerDto,
  CreateOrEditCustomerTerritoryDto,
} from "@/shared/service-proxies/generated-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

interface UsableCustomers {
  customers: Ref<Array<IGetCustomerForViewDto>>;
  customer: Ref<IGetCustomerForViewDto>;
  customerTerritory: Ref<IGetCustomerTerritoryForViewDto>;
  customerTerritories: Ref<Array<IGetCustomerTerritoryForViewDto>>;
  getCustomers: (filters: CustomersFilter) => void;
  exportData: (filters: CustomersFilter) => Promise<boolean>;
  getCustomer: (customerId: string) => void;
  getCustomerTerritories: (customerId: string) => void;
  getCustomersTerritories: (filters: TerritoryFilter) => void;
  updateOrCreateCustomer: (customer: CreateOrEditCustomerDto) => void;
  updateOrCreateCustomerTerritory: (
    customer: CreateOrEditCustomerTerritoryDto
  ) => Promise<boolean>;
  deleteCustomer: (id: string) => Promise<boolean>;
  deleteCustomerTerritory: (id: string) => Promise<boolean>;
}

const useCustomers = (): UsableCustomers => {
  const customers: Ref<Array<IGetCustomerForViewDto>> = ref(
    [] as unknown as Array<IGetCustomerForViewDto>
  );

  const client = new CustomersServiceProxy(APP_BASE_URL, axiosClient);
  const territoryProxy = new CustomerTerritoriesServiceProxy(
    APP_BASE_URL,
    axiosClient
  );

  const customer: Ref<IGetCustomerForViewDto> = ref(
    null as unknown as IGetCustomerForViewDto
  );

  const customerTerritory: Ref<IGetCustomerTerritoryForViewDto> = ref(
    null as unknown as IGetCustomerTerritoryForViewDto
  );

  const customerTerritories: Ref<Array<IGetCustomerTerritoryForViewDto>> = ref(
    null as unknown as IGetCustomerTerritoryForViewDto[]
  );

  const store = useStore();

  const getCustomers = async (filters: CustomersFilter) => {
    const {
      filter,
      firstNameFilter,
      lastNameFilter,
      phoneNumberFilter,
      outletNameFilter,
      outletAddressFilter,
      landmarkFilter,
      retailChannelFilter,
      regionFilter,
      stateFilter,
      lGAFilter,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken,
      priceListFilter,
    } = filters;

    await client
      .getAll(
        filter,
        firstNameFilter,
        lastNameFilter,
        phoneNumberFilter,
        outletNameFilter,
        outletAddressFilter,
        landmarkFilter,
        retailChannelFilter,
        regionFilter,
        stateFilter,
        lGAFilter,
        priceListFilter,
        sorting,
        skipCount,
        maxResultCount,
        cancelToken
      )
      .then((data) => {
        customers.value =
          data.items as unknown as Array<IGetCustomerForViewDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getCustomer = async (customerId: string) => {
    await client
      .getCustomerForView(customerId)
      .then((data) => {
        customer.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getCustomerTerritories = async (customerId: string) => {
    await client
      .getCustomerTerritories(customerId)
      .then((data) => {
        customerTerritories.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getCustomersTerritories = async (filters: TerritoryFilter) => {
    const [
      filter,
      customerFirstNameFilter,
      territoryTerritoryNameFilter,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken,
    ] = [...Object.values(filters)];

    await territoryProxy
      .getAll(
        filter,
        customerFirstNameFilter,
        territoryTerritoryNameFilter,
        sorting,
        skipCount,
        maxResultCount,
        cancelToken
      )
      .then((data) => {
        customerTerritories.value =
          data.items as unknown as Array<IGetCustomerTerritoryForViewDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const updateOrCreateCustomer = async (customer: CreateOrEditCustomerDto) => {
    await client
      .createOrEdit(customer)
      .catch((error) => store.commit(Mutations.SET_ERROR, error));
  };

  const updateOrCreateCustomerTerritory = async (
    customerTerritory: CreateOrEditCustomerTerritoryDto
  ) => {
    const resp = await territoryProxy
      .createOrEdit(customerTerritory)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
        return false;
      });

    return resp;
  };

  const deleteCustomer = async (id: string): Promise<boolean> => {
    const resp = await client
      .delete(id)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
        return false;
      });
    return resp;
  };

  const deleteCustomerTerritory = async (id: string): Promise<boolean> => {
    const resp = await territoryProxy
      .delete(id)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
        return false;
      });
    return resp;
  };

  const exportData = async (filters: CustomersFilter) => {
    const {
      filter,
      firstNameFilter,
      lastNameFilter,
      phoneNumberFilter,
      outletNameFilter,
      outletAddressFilter,
      landmarkFilter,
      retailChannelFilter,
      regionFilter,
      stateFilter,
      lGAFilter,
      cancelToken,
    } = filters;
    const resp = await client
      .getCustomersToExcel(
        filter,
        firstNameFilter,
        lastNameFilter,
        phoneNumberFilter,
        outletNameFilter,
        outletAddressFilter,
        landmarkFilter,
        retailChannelFilter,
        regionFilter,
        stateFilter,
        lGAFilter,
        cancelToken
      )
      .then((res) => {
        fileDownload(res.fileToken, res.fileName);
        return true;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
        return false;
      });
    return resp;
  };

  return {
    customers,
    customer,
    customerTerritory,
    customerTerritories,
    getCustomers,
    getCustomersTerritories,
    getCustomer,
    deleteCustomer,
    deleteCustomerTerritory,
    updateOrCreateCustomerTerritory,
    getCustomerTerritories,
    updateOrCreateCustomer,
    exportData,
  };
};

export default useCustomers;
