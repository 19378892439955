
  import { defineComponent, computed, onMounted, ref } from "vue";
  import type { PropType } from "vue";
  import { Form } from "vee-validate";
  import type { ICustomerDto } from "@/shared/service-proxies/generated-proxies";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import usePriceList from "@/composables/usePriceList";
  import { PriceListFilter } from "@/models";

  export default defineComponent({
    name: "CustomerForm",
    components: { Form },
    props: {
      modelValue: {
        type: Object as PropType<ICustomerDto>,
        required: true,
      },
      saving: {
        type: Boolean,
      },
    },
    emits: ["update:modelValue", "submit"],
    setup(props, { emit }) {
      const route = useRoute();
      const router = useRouter();
      const store = useStore();
      const customer = computed({
        get: () => props.modelValue,
        set: (val) => emit("update:modelValue", val),
      });

      const { priceLists, getPriceLists } = usePriceList();

      const breadcrumbs = computed(() => {
        return store.getters.pageBreadcrumbPath;
      });

      onMounted(async () => {
        await getPriceListData();
      });

      const defaultFilter = ref({
        sorting: null,
        skipCount: 0,
        maxResultCount: 1000,
      } as unknown as PriceListFilter);

      const getPriceListData = async () => {
        await getPriceLists(defaultFilter.value as unknown as PriceListFilter);
      };

      const pageTitle = computed(() => {
        return store.getters.pageTitle;
      });

      return { customer, route, router, breadcrumbs, pageTitle, priceLists };
    },
  });
